<template>
  <div>
    <nav aria-label="breadcrumb" class="breadcrumb-nav" style="background-color:white ;">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'Home' }">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'sitemapHtml' }">Category</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'Category', params: { categoryUrl: $route.params.categoryUrl } }">{{ categoryInfos.title
          }}</router-link>
        </li>
      </ol>
    </nav>
    <!--Products-->
    <div class="products mt-3">
      <div class="row align-items-center mb-3">
        <div class="col-lg-8">
          <h2 class="title pb-0 mb-0">{{ categoryInfos.title}}</h2>
        </div>
        <div class="col-lg-4">
          <div class="d-flex justify-content-end">
            <!-- <select class="form-select shadow-none w-auto mr-2" aria-label="Default select example">
                        <option selected>Shorting</option>
                        <option value="1">Low</option>
                        <option value="1">High</option>
                        <option value="1">High to Low</option>
                        <option value="2">Low to High</option>
                        <option value="3">All</option>
                    </select> -->
            <select class="form-select shadow-none w-auto" aria-label="Default select example" v-model="numOfData"
              @change="pageIndexReset(); showDatas();">
              <option selected>Show Item</option>
              <option value="42">42</option>
              <option value="84">84</option>
              <option value="168">168</option>
              <option value="336">336</option>
            </select>
          </div>
        </div>
      </div>
      <div class="grid">
        <!-- <pre>{{categoryInfos}}</pre> -->
        <div class="grid-item" v-for="(product, index) in dataList.data" :key="index">
          <div class="pro-card card shadow-sm">
            <slot v-if="product.hasDiscount == 1 && isDiscountTimeExpire(product.discountStartAt, product.discountEndAt)">
              <span class="pro-badge offer" data-label="Offer"></span>
            </slot>

            <slot v-if="getProductQuantity(product.quantity_infos) < 1">
              <span class="pro-badge sold-out" data-label="Sold Out"></span>
            </slot>
            <!-- <slot v-else-if="product.date">
                        <span class="pro-badge new" data-label="New"></span>
                    </slot> -->

            <slot v-if="product.product_image != null">
              <router-link :to="{ name: 'ProductDetails', params: { slug: product.targetUrl } }">
                <img v-lazy="product.product_image.baseUrl + product.product_image.productImage" class="d-block w-100"
                  :alt="product.name">
              </router-link>
            </slot>
            <div class="card-body">
              <h3>
                <router-link :to="{ name: 'ProductDetails', params: { slug: product.targetUrl } }">{{ product.name
                }}</router-link>
              </h3>
            </div>
            <div class="card-footer bg-transparent border-0">
              <div class="content">
                <div class="price">
                  <slot v-if="product.quantity_infos != null">
                    <slot
                      v-if="product.hasDiscount == 1 && isDiscountTimeExpire(product.discountStartAt, product.discountEndAt)">
                      <div class="cost-price">৳ {{ product.quantity_infos | sellPrice }}</div>
                      <div class="sell-price ">৳ {{ parseInt(product.discount) }}</div>
                    </slot>
                    <slot v-else>
                      <div class="sell-price">৳ {{ product.quantity_infos | sellPrice }}</div>
                    </slot>
                  </slot>
                </div>
                <slot v-if="product.quantity_infos != null">
                  <slot v-if="getProductQuantity(product.quantity_infos) > 0">
                    <router-link :to="{ name: 'ProductDetails', params: { slug: product.targetUrl } }" type="button"
                      class="cart" v-if="product.hasSizeVarity == 1 || product.hasColorVarity == 1">
                      <i class="mdi mdi-cart-plus"></i>
                      <span>Add to cart</span>
                    </router-link>
                    <button type="button" class="cart" v-on:click="addToCart(product.id)" v-else>
                      <i class="mdi mdi-cart-plus"></i>
                      <span>Add to cart</span>
                    </button>
                  </slot>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="foot text-center pt-4">
            <a href="products.html" class="btn btn-success shadow-none">View All</a>
        </div> -->
      <div class="row mt-3">
        <div class="col-lg-12">
          <nav aria-label="Page navigation example">
            <paginate :page-count="lastPage" :click-handler="dataByPaginate" :page-range="2" :prev-text="'<'"
              :next-text="'>'" :container-class="'pagination justify-content-end'" :page-class="'page-item'"
              :page-link-class="'page-link'" :prev-class="'page-item'" :prev-link-class="'page-link'"
              :next-class="'page-item'" :next-link-class="'page-link'" :first-last-button="true" :first-button-text="'<<'"
              :last-button-text="'>>'">
              <span slot="prevContent">Changed previous button</span>
              <span slot="nextContent">Changed next button</span>
              <span slot="breakViewContent">
                <svg width="16" height="4" viewBox="0 0 16 4">
                  <circle fill="#999999" cx="2" cy="2" r="2" />
                  <circle fill="#999999" cx="8" cy="2" r="2" />
                  <circle fill="#999999" cx="14" cy="2" r="2" />
                </svg>
              </span>
            </paginate>
          </nav>
        </div>
      </div>
    </div>
    <!--/Products-->
  </div>
</template>
<script>
import moment from "moment";
import $ from 'jquery';
export default {
  data() {
    return {
      msgFlag: false,
      errMsgFlag: false,
      msg: null,
      errMsg: null,

      numOfData: 42,
      urlParam: {
        numOfData: 42,
        // categoryId:this.$route.params.categoryId,
        slug: this.$route.params.categoryUrl,
      },
      // categoryId:this.$route.params.categoryId,
      categoryUrl: this.$route.params.categoryUrl,
      numOfDataPage: 0,
      isClass: false,
      dataUrl: "categorywise/products",
      dataList: [],
      value: 1,
      max: 5,
      min: 1,
      viewImg: 'https://picsum.photos/900/700',
      colorCode: 0,
      sizeCode: 0,
      lastPage: 0,
      metaProductInfo: {},
      metaProductImage: null,
      metaImage:require('../assets/logo.png'),
      categoryInfos: {
        title:''
      },
    }
  },
  metaInfo() {
    return {
      title: ` ${this.categoryInfos.metaTitle} `,
      meta: [
        { name: 'author', content: "Makeup World BD" },
        { name: 'keywords', content: this.categoryInfos.metaKey },
        { name: 'description', content: this.categoryInfos.metaDetails },
        { name: 'title', content:this.categoryInfos.metaTitle},
        { property: 'og:title', content: this.categoryInfos.metaTitle},
        { property: 'og:site_name', content: 'Makeup World BD' },
        { property: 'og:description', content: this.categoryInfos.metaDetails},
        { property: 'og:type', content: 'products' },
        { property: 'og:url', content: location.href },
        { property: 'og:image', content: this.metaImage }
      ]
    }
  },
  beforeMount() {
    this.getDataList(this.dataUrl, this.urlParam);
  },
  mounted() {
    // this.getDataList(this.dataUrl,this.urlParam);
  },
  watch: {
    $route(to, from) { this.numOfData = 42; this.pageIndexReset(); this.showDatas(); }
  },

  methods: {
    setMetaInfo() {
      $("title").text(this.categoryInfos.metaTitle);
      $('.title').attr('content', this.categoryInfos.metaTitle);
      $('.description').attr('content', this.categoryInfos.metaDetails);
      $('.keyword').attr('content', this.categoryInfos.metaKey);
      $('.image').attr('content', this.categoryInfos.menu_image.baseUrl+this.categoryInfos.menu_image.menuImage);
      $('.url').attr('content', location.href);

      // Set Twitter meta tags
      $('meta[name="twitter:card"]').attr('content', 'summary_large_image');
      $('meta[name="twitter:title"]').attr('content', this.categoryInfos.metaTitle);
      $('meta[name="twitter:description"]').attr('content', this.categoryInfos.metaDetails);
      $('meta[name="twitter:image"]').attr('content', this.categoryInfos.menu_image.baseUrl+this.categoryInfos.menu_image.menuImage);


      $('.author').attr('content', "makeup world bd");
      $('meta[name="keywords"]').attr('content', this.categoryInfos.metaKey);
      $('meta[name="description"]').attr('content', this.categoryInfos.metaDetails);
      $('meta[property="og:title"]').attr('content', this.categoryInfos.metaTitle);
      $('meta[property="og:site_name"]').attr('content', 'Makeup World BD');
      $('meta[property="og:description"]').attr('content', this.categoryInfos.metaDetails);
      $('meta[property="og:type"]').attr('content', 'products');
      $('meta[property="og:url"]').attr('content', location.href);
      $('meta[property="og:image"]').attr('content', this.categoryInfos.menu_image.baseUrl+this.categoryInfos.menu_image.menuImage);
    },

    isDiscountTimeExpire(startAt, endAt) {
      endAt = moment(endAt); //Date format
      startAt = moment(startAt); //Date format
      let today = moment();
      let isAfter = moment(endAt).isAfter(today);
      let isBefore = moment(startAt).isBefore(today);
      if (isAfter && isBefore)
        return true;
      else
        return false;
    },
    getSideProductsAndPopularTags() {

      this.$http.get("random/side/product/and/popular/tag", { params: { numOfData: this.totalData } })
        .then(response => {
          this.sideProducts = response.data;
          // this.popularTags=response.data.popularTags;
        })
        .catch(error => { console.dir(error) })
        .finally();
    },
    addToCart(productId) {
      let cartInfos = this.$store.state.cartInfos;

      let formData = new FormData();

      let flag = false;

      cartInfos.forEach(cartInfo => {
        if (
          cartInfo.productId == productId &&
          cartInfo.isFreeProduct != true &&
          parseInt(cartInfo.colorId) == 0 &&
          parseInt(cartInfo.sizeId) == 0
        ) {
          let quantity = parseFloat(cartInfo.quantity) + 1;
          formData.append("productId[]", productId);
          formData.append("quantity[]", quantity);
          formData.append("isFreeProduct[]", false);
          formData.append("colorCode[]", cartInfo.colorId);
          formData.append("sizeCode[]", cartInfo.sizeId);
          formData.append("addedToCart[]", true);
          flag = true;
        }
        else
          if (cartInfo.isFreeProduct != true) {

            formData.append("productId[]", cartInfo.productId);
            formData.append("quantity[]", cartInfo.quantity);
            formData.append("isFreeProduct[]", cartInfo.isFreeProduct);
            formData.append("colorCode[]", cartInfo.colorId);
            formData.append("sizeCode[]", cartInfo.sizeId);
            formData.append("addedToCart[]", false);
          }
      });
      if (flag != true) {
        formData.append("productId[]", productId);
        formData.append("quantity[]", 1);
        formData.append("isFreeProduct[]", false);
        formData.append("colorCode[]", 0);
        formData.append("sizeCode[]", 0);
        formData.append("addedToCart[]", true);
      }


      // let formData=new FormData();
      //  formData.append("productId",productId);
      //  formData.append("colorCode",this.colorCode);
      //  formData.append("sizeCode",this.sizeCode);
      //  formData.append("quantity",1);

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };


      this.$http.post("product/add/to/cart", formData, config)
        .then(response => {
          if (response.data.msgFlag == true) {
            // console.dir(response.data);
            this.$store.commit("storeCartInfos", response.data.cartInfos);
            this.$store.commit("storePriceInfos", response.data);
            this.$toaster.success(response.data.msg);
          }
          if (response.data.errMsgFlag == true) {
            this.$store.commit("storeCartInfos", response.data.cartInfos);
            this.$store.commit("storePriceInfos", response.data);
            this.$toaster.warning(response.data.errMsg);
          }
        })
        .catch(error => { console.dir(error) })
        .finally();
    },
    getQuantityLabel(quantityType) {
      let label = "1 PCS";
      if (quantityType != null) {
        label = quantityType.smallQuantity + " " + quantityType.label;
      }
      return label;
    },
    getProductDetails(productId, productName) {
      this.$router.push({ name: 'ProductDetails', params: { productId: productId, slug: productName.split(' ').join('-') } });

    },

    getProductQuantity(stockInfos) {
      let quantity = 0;
      stockInfos.forEach(stockInfo => {
        quantity = quantity + stockInfo.quantity;
      });
      return quantity;
    },
    getDataList(url, param) {
      if (this.numOfDataPage > 0) {
        url = url + "?page=" + this.numOfDataPage;
      }
      this.$http.get(url, { params: param })
        .then(response => {
          if (response.data.msgFlag == true) {
            this.dataList = response.data.dataList;
            this.lastPage = this.dataList.last_page;
            this.totalData = this.dataList.total;
            this.categoryInfos = response.data.categoryInfo;
            if(this.categoryInfos.menu_image!=null)
              this.metaImage=this.categoryInfos.menu_image.baseUrl+this.categoryInfos.menu_image.menuImage;

            if (this.dataList.data.length > 0) {
              this.metaProductInfo = this.dataList.data[0];
              if (this.metaProductInfo.product_image != null)
                this.metaProductImage = this.metaProductInfo.product_image.baseUrl + this.metaProductInfo.product_image.productImage
            
                let schemaTag= this.$options.filters.schemaFilter(this.metaProductInfo);//this.schemaFilter(this.metaProductInfo);
            
              // $("#schema").text(JSON.stringify(schemaTag));
              $('link[rel=canonical]').attr('href',  location.href);
             // this.setMetaInfo();
            }
          }

        })
        .catch(error => {
          console.dir(error);
        });

    },
    dataByPaginate(pageIndex) {
      this.numOfDataPage = pageIndex;
      this.showDatas();
    },
    showDatas() {
      this.urlParam = {
        numOfData: this.numOfData,
        // categoryId:this.$route.params.categoryId,
        slug: this.$route.params.categoryUrl,
      };
      this.getDataList(this.dataUrl, this.urlParam);
      // this.getSideProductsAndPopularTags();
    },
    pageIndexReset() {
      this.numOfDataPage = 1;
    },
    storeRequestProductInfo(productId, productName) {
      this.$store.commit("clearRequestProductInfo");
      let data = {
        productId: productId,
        productName: productName,
      };

      this.$store.commit("storeRequestProductInfo", data);
    },
    schemaFilter(productInfo) {
      productInfo['@context'] = "http://schema.org";
      productInfo['@type'] = "Product";
      return productInfo
    }

  }
}
</script>

<style></style>